import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Hammer from 'hammerjs';
import {artworks} from '../data/artworks';
import { dontbuyArtworks } from '../data/dontbuy-artvote';
import './SwiperComponent.css';
import * as amplitude from '@amplitude/analytics-browser';
import * as assets from '../assets/assets'; 
import * as db from '../firebase/firebaseDB';
import { signInWithGoogle, signInWithTwitter } from '../Auth/auth';
import { fetchNFTs } from '../WalletConnect/Wallet';
import { wagmiConfig, modal } from '../WalletConnect/Wallet';
import {useAccount} from 'wagmi'
import {getConnections} from '@wagmi/core'

const allArtworks = {
  'UAO': artworks,
  'dontbuy': dontbuyArtworks,
};

let curCollection = 'UAO';

function SwiperComponent() {
  const boardRef = useRef(null);

  useEffect(() => {
    function logSkipOrUpvoteEvent(event, artworkId) {
      amplitude.logEvent(event, {
        artworkId: artworkId,
        deviceId: deviceId,
        collection: curCollection,
        action_time: new Date().toISOString(),
      });
    }
    
    function logLinkClickedEvent(event) {
      amplitude.logEvent(event, {
        deviceId: deviceId,
        action_time: new Date().toISOString(),
      });
    }

    function setTheLastViewedArtwork(artworkIndex) {
      localStorage.setItem(curCollection, artworkIndex);
    }

    function getTheLastViewedArtwork() {
      return localStorage.getItem(curCollection);
    }

    const artworksCount = allArtworks[curCollection].length;
    let artworkIndex = -1;
    let artworkIndexToShow = -1;
    const countToShow = 5;

    class Carousel {
      constructor(element) {
        this.board = element;

        let index = getTheLastViewedArtwork();
        if (index != null) {
          artworkIndex = Number(index) + 1;
          artworkIndexToShow = artworkIndex;
        }

        // Add initial cards
        for (let i = 0; i < countToShow; i++) {
          this.push();
        }

        // Event listeners
        const upvoteButton = document.getElementById('upvoteButton');
        upvoteButton.addEventListener('click', () => {
          if (artworkIndexToShow < artworksCount) {
            this.simulateSwipe(board.clientWidth);
            if (artworkIndexToShow >= 0)
              logSkipOrUpvoteEvent('artwork_upvoted', allArtworks[curCollection][artworkIndexToShow].id);
          }
        });

        const skipButton = document.getElementById('skipButton');
        skipButton.addEventListener('click', () => {
          if (artworkIndexToShow < artworksCount) {
            this.simulateSwipe(-2 * board.clientWidth);
            if (artworkIndexToShow >= 0)
              logSkipOrUpvoteEvent('artwork_skipped', allArtworks[curCollection][artworkIndexToShow].id);
          }
        });

        document.getElementById('endLink').addEventListener('click', () => {
          logLinkClickedEvent('holst_website_visited');
        });

        document.getElementById('endButton').addEventListener('click', () => {
          logLinkClickedEvent('holst_twitter_visited');
        });

        // Init the card effect and colors
        const cardEffect = document.createElement('div');
        cardEffect.id = 'overlay';
        cardEffect.classList.add('overlay');
        cardEffect.innerHTML = '<div class="overlay-text">skip</div>'
        this.board.appendChild(cardEffect);
        this.cardEffect = cardEffect;

        this.mintColor = '#41D3BD';
        this.greyColor = '#60656F';

        this.isMuted = true;

        // Handle gestures
        this.handle();
      }

      handle() {
        // List all cards
        this.cards = this.board.querySelectorAll('.card');
        if (artworkIndexToShow >= artworksCount) {
          document.getElementById('skipButton').classList.add('hidden');
          document.getElementById('upvoteButton').classList.add('hidden');
          document.getElementById('overlay').classList.add('hidden');
          document.getElementById('endScreen').classList.remove('hidden');
          return;
        }

        // Get top card
        this.topCard = this.cards[this.cards.length - 1];

        if (artworkIndexToShow >= 0 && allArtworks[curCollection][artworkIndexToShow].type.startsWith("video")) {
          const asset = this.topCard.firstChild.firstChild;
          if (asset.readyState >= 1) {
            asset.play();
            console.log(`${allArtworks[curCollection][artworkIndexToShow].id} is playing`);
          } else {
            asset.addEventListener('canplay', () => {
              asset.play();
              console.log(`${allArtworks[curCollection][artworkIndexToShow].id} is playing`);
            });
          }
        }

        // Get next card
        this.nextCard = this.cards[this.cards.length - 2];

        // If at least one card is present
        if (this.cards.length > 0) {
          // Set default top card position and scale
          this.topCard.style.transform =
            'translateX(-50%) translateY(-5%) rotate(0deg) rotateY(0deg) scale(1)';
          this.cardEffect.style.transform = 'translateX(-50%) translateY(-5%) rotate(0deg) rotateY(0deg) scale(1)';

          // Destroy previous Hammer instance, if present
          if (this.hammer) this.hammer.destroy();

          // Listen for tap and pan gestures on top card
          this.hammer = new Hammer(this.topCard);

          this.hammer.add(new Hammer.Tap());
          this.hammer.add(
            new Hammer.Pan({
              position: Hammer.position_ALL,
              threshold: 0,
            })
          );

          // Pass events data to custom callbacks
          this.hammer.on('tap', (e) => this.onTap(e));
          this.hammer.on('pan', (e) => this.onPan(e));

          setTimeout(() => {
            this.topCard.style.transition = 'transform 0.05s linear';
            this.cardEffect.classList.remove('hidden');
          }, 200);

          this.isMuted = true;
        }
      }

      onTap(e) {
        // Get finger position on top card
        const propX = (e.center.x - e.target.getBoundingClientRect().left) / e.target.clientWidth;

        // Get rotation degrees around Y axis (+/- 15) based on finger position
        const rotateY = 15 * (propX < 0.05 ? -1 : 1);

        // Enable transform transition
        // this.topCard.style.transition = 'transform 100ms ease-out';

        // Apply rotation around Y axis
        this.topCard.style.transform =
          `translateX(-50%) translateY(-5%) rotate(0deg) rotateY(${rotateY}deg) scale(1)`;

        // Wait for transition end
        setTimeout(() => {
          // Reset transform properties
          this.topCard.style.transform =
            'translateX(-50%) translateY(-5%) rotate(0deg) rotateY(0deg) scale(1)';
        }, 100);
      }

      onPan(e) {
        if (!this.isPanning) {
          this.isPanning = true;

          // Remove transition properties
          this.topCard.style.transition = null;
          if (this.nextCard) this.nextCard.style.transition = null;

          // Get top card coordinates in pixels
          const style = window.getComputedStyle(this.topCard);
          const mx = style.transform.match(/^matrix\((.+)\)$/);
          this.startPosX = mx ? parseFloat(mx[1].split(', ')[4]) : 0;
          this.startPosY = mx ? parseFloat(mx[1].split(', ')[5]) : 0;

          // Get top card bounds
          const bounds = this.topCard.getBoundingClientRect();

          // Get finger position on top card, top (1) or bottom (-1)
          this.isDraggingFrom = e.center.y - bounds.top > this.topCard.clientHeight / 2 ? -1 : 1;
          this.width = this.topCard.getBoundingClientRect().width;
        }

        // Get new coordinates
        let posX = e.deltaX + this.startPosX;
        let posY = e.deltaY + this.startPosY;

        // Get ratio between swiped pixels and the axes
        const propX = e.deltaX / this.board.clientWidth;
        const propY = e.deltaY / this.board.clientHeight;

        // Get swipe direction, left (-1) or right (1)
        const dirX = e.deltaX < 0 ? -1 : 1;

        // Get degrees of rotation, between 0 and +/- 45
        const deg = this.isDraggingFrom * dirX * Math.abs(propX) * 45;

        // Get scale ratio, between .95 and 1
        const scale = (95 + 5 * Math.abs(propX)) / 100;

        // Move and rotate top card
        this.topCard.style.transform =
          `translateX(${posX}px) translateY(${posY}px) rotate(${deg}deg) rotateY(0deg) scale(1)`;
        
        // Move and rotate card effect + changing the color
        if (artworkIndexToShow >= 0) { 
          this.cardEffect.style.backgroundColor = posX > -this.width / 2 ? this.mintColor : this.greyColor;
          this.cardEffect.innerHTML = `<div class="overlay-text">${posX > -this.width / 2 ? 'upvote' : 'skip'}</div>`;
          this.cardEffect.style.opacity = `${Math.abs(propX * 2)}`
          this.cardEffect.style.transform = `translateX(${posX}px) translateY(${posY}px) rotate(${deg}deg) rotateY(0deg) scale(1)`;
        }

        // Scale up next card
        if (this.nextCard) {
          this.nextCard.style.transform =
            `translateX(-50%) translateY(-10%) rotate(0deg) rotateY(0deg) scale(${scale})`;
        }

        if (e.isFinal) {
          this.isPanning = false;

          let successful = false;

          // Set back transition properties
          this.topCard.style.transition = 'transform 300ms ease-out';
          this.cardEffect.style.transition = 'transform 300ms ease-out'
          if (this.nextCard) this.nextCard.style.transition = 'transform 0.05s linear';

          // Check threshold and movement direction
          if (propX > 0.2 && e.direction === Hammer.DIRECTION_RIGHT) {
            successful = true;
            // Get right border position
            posX = this.board.clientWidth;
          } else if (propX < -0.2 && e.direction === Hammer.DIRECTION_LEFT) {
            successful = true;
            // Get left border position
            posX = -(this.board.clientWidth + this.topCard.clientWidth);
          } 
          // If we need to set the top swipe, uncomment it 
          // else if (propY < -0.2 && e.direction === Hammer.DIRECTION_UP) {
          //   successful = true;
          //   // Get top border position
          //   posY = -(this.board.clientHeight + this.topCard.clientHeight);
          // }

          if (successful) {
            // Throw card in the chosen direction
            this.topCard.style.transform =
              `translateX(${posX}px) translateY(${posY}px) rotate(${deg}deg)`;
            this.cardEffect.style.transform = 
              `translateX(${posX}px) translateY(${posY}px) rotate(${deg}deg)`;
            if (this.nextCard) {
              this.nextCard.style.transition = 'transform 0.2s linear';
            }

            if (posX > 0 && artworkIndexToShow >= 0) {
              // Logging upvote event
              logSkipOrUpvoteEvent('artwork_upvoted', allArtworks[curCollection][artworkIndexToShow].id);
            } else if (posX < 0 && artworkIndexToShow >= 0) {
              // Logging skip event
              logSkipOrUpvoteEvent('artwork_skipped', allArtworks[curCollection][artworkIndexToShow].id);
            }

            // Set artwork index to the memory
            setTheLastViewedArtwork(artworkIndexToShow);

            this.showNextCard(300);
          } else {
            // Reset cards position and size
            this.topCard.style.transform =
              'translateX(-50%) translateY(-5%) rotate(0deg) rotateY(0deg) scale(1)';
            this.cardEffect.style.transform = 
              'translateX(-50%) translateY(-5%) rotate(0deg) rotateY(0deg) scale(1)';
            this.cardEffect.style.opacity = 0;
            this.cardEffect.style.transition = 'transform 0.05s linear, opacity 0.01s ease-in-out';
            if (this.nextCard) {
              this.nextCard.style.transform =
                'translateX(-50%) translateY(-10.5%) rotate(0deg) rotateY(0deg) scale(0.93)';
            }
          }
        }
      }

      push() {
        if (artworkIndex >= artworksCount) return; // If there's no cards to show

        const card = document.createElement('div');
        if (artworkIndex === -1) {
          card.classList.add('onboarding');
          card.innerHTML = `<img id ="onboardingUpvoteImg" src=${assets.onboardingUpvoteImg}></img>
        <div class="onboarding-text onboarding-text-upvote">
          swipe right to upvote
        </div>
        <img id ="onboardingSkipImg" src=${assets.onboardingSkipImg}></img>
        <div class="onboarding-text onboarding-text-skip">
          swipe left to skip
        </div>`;
        } else {
          card.innerHTML = `<div id="cardTitle">${allArtworks[curCollection][artworkIndex].name}</div><div id="cardAuthor">${allArtworks[curCollection][artworkIndex].artist_name}</div>`;

          if (allArtworks[curCollection][artworkIndex].type.startsWith('video')) {
            card.innerHTML = `<div id="squareContainer" class="non-highlightable"><video class="background-media" src="${allArtworks[curCollection][artworkIndex].src}" loop muted playsinline></video><div id="loader" class="loader-container"><div class="loader"></div></div><img id="volumeButton" src=${assets.mutedButton}></img></div>` + card.innerHTML;
            const videoElement = card.querySelector('.background-media');
            const loaderElement = card.querySelector('.loader-container');
            const volBtn = card.querySelector('#volumeButton');

            videoElement.addEventListener('loadeddata', () => {
              loaderElement.style.display = 'none'; // Hide loader when video is loaded
            });

            volBtn.addEventListener('click', () => {
              volBtn.src = this.isMuted ? assets.unmutedButton : assets.mutedButton;
              this.isMuted = !this.isMuted;
              videoElement.muted = this.isMuted;
              volBtn.classList.add(this.isMuted ? 'muted' : 'unmuted');
            });
          } else {
            card.innerHTML = `<div id="squareContainer" class="non-highlightable"><img class="background-media" src="${allArtworks[curCollection][artworkIndex].src}"><div id="loader" class="loader-container"><div class="loader"></div></div></div>` + card.innerHTML;

            const imgElement = card.querySelector('.background-media');
            const loaderElement = card.querySelector('.loader-container');
          
            imgElement.addEventListener('load', () => {
              loaderElement.style.display = 'none'; // Hide loader when image is loaded
            });
          }
        }
        
        card.classList.add('card');
        this.board.insertBefore(card, this.board.firstChild);
        ++artworkIndex;
      }

      simulateSwipe(xToSwipe) {
        if (artworkIndexToShow >= artworksCount) return;
        
        this.isPanning = true;

        if (this.nextCard) {
          this.nextCard.style.transform =
            `translateX(-50%) translateY(-10%) rotate(0deg) rotateY(0deg) scale(${1})`;
        }

        // Remove transition properties
        this.topCard.style.transition = null;
        if (this.nextCard) this.nextCard.style.transition = null;

        // Simulate swipe
        let posX = xToSwipe;
        let posY = 0;
        let deg = posX > 0? 15: -15;

        // Set back transition properties
        this.topCard.style.transition = 'transform 1000ms linear, opacity 1000ms linear';
        if (this.nextCard) this.nextCard.style.transition = 'transform 0.2s linear';

        // Throw card in the chosen direction
        this.topCard.style.transform =
          `translateX(${posX}px) translateY(${posY}px) rotate(${deg}deg)`;
        this.topCard.style.opacity = 0;
        if (artworkIndexToShow >= 0) { 
          this.cardEffect.style.transition = 'transform 1000ms linear, opacity 1000ms linear';
          this.cardEffect.style.backgroundColor = posX > 0 ? this.mintColor : this.greyColor;
          this.cardEffect.innerHTML = `<div class="overlay-text">${posX > 0 ? 'upvote' : 'skip'}</div>`;
          this.cardEffect.style.opacity = 1;
          this.cardEffect.style.transform = `translateX(${posX}px) translateY(${posY}px) rotate(${deg}deg) rotateY(0deg) scale(1)`;
        }

        // Set artwork index to the memory
        setTheLastViewedArtwork(artworkIndexToShow);

        this.showNextCard(700);
      }

      showNextCard(waitTime){
        // Wait transition end
        setTimeout(() => {
          this.cardEffect.style.transition = 'transform 0.05s linear, opacity 0.01s linear';
          this.cardEffect.style.opacity = 0;
          this.cardEffect.classList.add('hidden');

          // Remove swiped card
          this.board.removeChild(this.topCard);
          ++artworkIndexToShow;
          // Add new card
          this.push();
          // Handle gestures on new top card
          this.handle();
        }, waitTime); 
      }
    }

    const board = boardRef.current;
    let deviceId = null;
    let userId = null;
    if (board) {
      // Init amplitude
      amplitude.init('54239493c8ee9ecfd01b4b7fb1dd6b84', {defaultTracking: true});
      deviceId = amplitude.getDeviceId();
      amplitude.setUserId(deviceId);
      userId = amplitude.getUserId();

      new Carousel(board);
      setTimeout(() => {
        document.getElementById('loadingImageContainer').classList.add('hidden');
        if (artworkIndexToShow < artworksCount) {
          document.getElementById('skipButton').classList.remove('hidden');
          document.getElementById('upvoteButton').classList.remove('hidden');
        }
      }, 3000);
    }
  }, []);

  const [searchParams] = useSearchParams();
  const artistName = searchParams.get('artist');
  
  if (artistName && artistName in allArtworks) curCollection = artistName;

  return (
    <div id="board" ref={boardRef} className="board">
      <div id="loadingImageContainer" class="non-highlightable">
        <img id="loadingImage" src={assets.logo}></img>
      </div>
       {/* <button onClick={signInWithGoogle}>Google</button>
      <button onClick={signInWithTwitter}>X</button>
      <button onClick={() => {fetchNFTs('0x0e52b4ddb54341d206d420725e7b27bb13a7365d', (NFTs) => {console.log(NFTs)}) }}>FETCH</button> */}
      {/* Cards will be dynamically added here */}
      <div id="skipButton" class="hidden non-highlightable">
        <img src={assets.skipButtonImg}></img>
        <div id="skipButtonText">skip</div>
      </div>
      <div id="upvoteButton" class="hidden non-highlightable">
        <img src={assets.upvoteButtonImg}></img>
        <div id="upvoteButtonText">upvote</div>
      </div>
      <div id="endScreen" class="hidden">
        <div id="ufoEmoji">🛸</div>
        <div id="endTitle">Thanks for participating</div>
        <div id="endInfo">Excited about what's next?<br/>We're building a web3 app for art lovers & artists.</div>
        <a id="endButton" class="non-highlightable" href='https://forms.gle/4dj8Pr5oaZm3gfjB6' target='_blank' rel="noopener noreferrer">Get 3 Months Free</a>
        <div id="endExplanation">Complete our survey to feature your collection for 3 months, including the Map, AR, TV app, and ArtVote swiper exposure.<br/><br/>Follow us for early access to our art discovery platform.</div>
        <a id="endLink" href='https://x.com/holstcity' target='_blank' rel="noopener noreferrer">Follow us on<img id="xLogo" src={assets.xLogo}/> </a>
      </div>
    </div>
  );
};

export default SwiperComponent;
